import './Article.scss';
import React from 'react';

export class Article extends React.Component {

	constructor(props) {
		super(props);

		let {
			expandedByDefault: expanded = false,
		} = this.props;

		const {
			cookiesConsent = false,
			id,
		} = this.props;
		console.log(`${id} constructor cookiesConsent`, cookiesConsent);
		if (cookiesConsent) {
			if (sessionStorage.getItem(`${id}-expanded`)) expanded = JSON.parse(sessionStorage.getItem(`${id}-expanded`));
		}

		this.state = ({
			cookiesConsent: cookiesConsent,
			expanded: expanded, // Set up from sessionStorage
			id: id,
		});

		this.toggleExpanded = this.toggleExpanded.bind(this);
	}

	componentDidUpdate = (prevProps) => {
		if (this.props.expandNow !== prevProps.expandNow) {
			this.expand();
		}

		// const {
		// 	cookiesConsent = false,
		// } = this.props;

	};

	expand = () => {
		const {
			cookiesConsent,
			id,
		} = this.state;

		if (cookiesConsent)sessionStorage.setItem(`${id}-expanded`, JSON.stringify(true));
		this.setState({
			expanded: true
		});
	};

	toggleExpanded = () => {
		const {
			cookiesConsent,
		} = this.state;
		let {
			expanded,
		} = this.state;
		const {
			id,
		} = this.state;
		expanded = !expanded;
		if (cookiesConsent)sessionStorage.setItem(`${id}-expanded`, JSON.stringify(expanded));
		this.setState({
			expanded: expanded
		});
		if ("vibrate" in navigator) {
			window.navigator.vibrate(100);
		}
	};

	render() {
		const {
			alt,
			children,
			dates = '',
			href = '',
			logo,
			mobileLogo,
			title,
		} = this.props;

		const {
			expanded,
			id,
		} = this.state;

		return (
			<article
				className={`article ${expanded ? 'expanded' : ''}`}
				id={`${id}`}
			>
				<header>
					<div
						className={`arrow ${expanded ? 'expanded' : ''}`}
						onClick={this.toggleExpanded}></div>
					<Anchor className='logo' href={href}>
						{mobileLogo !== undefined ? <img src={mobileLogo} className='logo mobile' alt={alt} /> : null}
						{logo !== undefined ? <img src={logo} className={`logo ${mobileLogo !== undefined ? 'non-mobile' : ''}`} alt={alt} /> : null}
					</Anchor>
					<h2>
						<Anchor className='logo' href={href}>
							{title}
						</Anchor>
						{dates !== '' ? ` ${dates}` : null}
					</h2>
				</header>
				<div className='content'>
					{children}
				</div>
			</article >
		);
	}
};

export class Anchor extends React.Component {
	render() {
		const {
			children,
			href = '',
			className = '',
		} = this.props;

		if (href !== '') {
			return (
				<a
					className={`${className}`}
					href={`${href}`}
					target='_blank'
					rel='noopener noreferrer'
				>{children}</a>
			);
		}
		return <>{children}</>;
	}
};
