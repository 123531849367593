import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import {
	createRoot
} from 'react-dom/client';
import React from 'react';

let cookiesConsent = false;
if (sessionStorage.getItem('cookies') !== 'accepted') {
	/* eslint-disable */
	cookiesConsent = window.confirm(`This page uses cookies to improve your experience. Do you wish to allow the use of those cookies?`, 'yes', 'no');
	/* eslint-enable */
	if (cookiesConsent) sessionStorage.setItem("cookies", "accepted");
}

if(cookiesConsent){
	// Add StatCounter?
	const scSettings = document.createElement('script');
	scSettings.setAttribute('type', 'text/javascript');
	scSettings.text = `
	var sc_project = 2339116;
	var sc_invisible = 1;
	var sc_security = "9a4ed257";
`;
	document.body.append(scSettings);
	const scScript = document.createElement('script');
	scScript.setAttribute('src', 'https://www.statcounter.com/counter/counter.js');
	scScript.setAttribute('type', 'text/javascript');
	document.body.append(scScript);

	// Add GA?
	const gaScript = document.createElement('script');
	gaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=UA-4275486-10');
	gaScript.setAttribute('type', 'text/javascript');
	gaScript.setAttribute('async', true);
	document.head.append(gaScript);

	const gaSettings = document.createElement('script');
	gaSettings.setAttribute('type', 'text/javascript');
	gaSettings.text = `
		window.dataLayer = window.dataLayer || [];
		function gtag() { dataLayer.push(arguments); }
		gtag('js', new Date());

		gtag('config', 'UA-4275486-10');
	`;
	document.head.append(gaSettings);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
